import logo from "./covid.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function Header() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="sticky">
                <Toolbar>
                    <img style={{ width: "50px", marginRight: "10px" }} src={logo} alt="Logo" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Mobile Coronavirus Testing
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            display: {
                                md: 'initial',
                                xs: 'none',
                            },
                        }}
                    >
                        <b>Email Us:</b> BackToSchoolTesting@gmail.com
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>

        // <Box sx={{ flexGrow: 1 }}>
        //   <AppBar position="sticky">
        //       <Toolbar>
        //           <img style={{ width: "50px", marginRight: "10px" }} src={logo} alt="Logo" />
        //           <Typography variant="h6" color="inherit" noWrap>
        //               Mobile Coronavirus Testing
        //           </Typography>

        //           <Button style={{ float: "right" }} variant="outlined" color="inherit">Login</Button>
        //       </Toolbar>
        //   </AppBar>
        //   </Box>
    );
}
