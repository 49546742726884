import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";

const theme = createTheme();

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Banner />
            <Footer />
        </ThemeProvider>
    );
}

export default App;
