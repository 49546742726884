import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import logo from "./covid-results.jpg";
import { AccessAlarm, Verified, Assignment } from "@mui/icons-material";

export default function Banner() {
    return (
        <main>
            <Grid container spacing={2} sx={{ display: { xs: "flex", md: "flex" } }}>
                <Grid item xs={12} md={7} sx={{ backgroundColor: "#121858" }}>
                    <center>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="white"
                            sx={{
                                pt: 4,
                                pb: 0,
                                fontSize: {
                                    md: 50,
                                    xs: 30,
                                },
                            }}
                            gutterBottom
                        >
                            <u>How It Works</u>
                        </Typography>
                        <Stack sx={{ pt: 2 }} direction="column" spacing={0}>
                            <Typography
                                variant="p"
                                color="white"
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    fontSize: {
                                        md: 25,
                                        xs: 30,
                                    },
                                }}
                                gutterBottom
                            >
                                <Assignment sx={{ mr: 2, fontSize: 40 }} />
                                <br></br>
                                <Button variant="contained" color="success"
                                    onClick={() => {
                                        window.location.replace('https://form.jotform.com/220252107746045');
                                    }}
                                >
                                    Click Here To Fill Out Patient Form
                                </Button>
                            </Typography>
                            <Typography
                                variant="p"
                                align="center"
                                color="white"
                                sx={{
                                    pt: 2,
                                    pb: 2,
                                    fontSize: {
                                        md: 25,
                                        xs: 20,
                                    },
                                }}
                                gutterBottom
                            >
                                <AccessAlarm sx={{ mr: 2, fontSize: 40 }} />
                                <br></br>Visit your testing site and get tested
                            </Typography>
                            <Typography
                                variant="p"
                                align="center"
                                color="white"
                                sx={{
                                    pt: 2,
                                    pb: 2,
                                    fontSize: {
                                        md: 25,
                                        xs: 20,
                                    },
                                }}
                                gutterBottom
                            >
                                <Verified sx={{ mr: 2, fontSize: 40 }} />
                                <br></br>Get test results within 48 hours
                            </Typography>
                        </Stack>
                        <Stack sx={{ pt: 2 }} direction="column" spacing={0}>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="white"
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    fontSize: {
                                        md: 50,
                                        xs: 30,
                                    },
                                }}
                                gutterBottom
                            >
                                <u>Questions?</u>
                            </Typography>
                            <Typography
                                variant="p"
                                align="center"
                                color="white"
                                sx={{
                                    pt: 2,
                                    mb: 6,
                                    fontSize: {
                                        md: 25,
                                        xs: 18,
                                    },
                                }}
                                gutterBottom
                            >
                                Email Us: <u>BackToSchoolTesting@gmail.com</u>
                            </Typography>
                        </Stack>
                    </center>
                </Grid>
                <Grid item xs={12} md={5} sx={{ backgroundColor: "#121858" }}>
                    <center>
                        <img style={{ width: "100%", paddingTop: "2%" }} src={logo} alt="Logo" />
                    </center>
                </Grid>
            </Grid>
        </main>
    );
}
